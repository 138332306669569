<template>
  <div style="display: contents;"></div>
</template>

<script>
import Excel from "exceljs";
import { mapGetters } from "vuex";

export default {
  props: {
    itemObj: {
      type: Object,
      default: () => ({})
    },
    groupList: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    try {
      const workbook = this.getWorkbook();
      const [sheet] = workbook.worksheets;
      this.setColumns(sheet);
      this.setFirstRow(sheet);
      const secondRow = this.setSecondRow(sheet);
      const dateArr = this.getDateArr();
      const mergeDate = [];
      const thirdRowData = ["", "Start", "End", ""];
      // 두번째 row 월별로 병합하기 위한 작업 및 세번째 row cell 데이터 넣기
      dateArr.forEach(({ year, month, date }, idx) => {
        thirdRowData.push(date);

        const { month: prevMonth } = mergeDate.at(-1) ?? {};
        const lastIndex = dateArr.length - 1;
        if (idx > 0 && prevMonth === month && idx !== lastIndex) return;

        const title = `${month}월-${year}`;
        mergeDate.push({ title, month, idx: idx + 5 });
      });
      this.setThirdRow(sheet, thirdRowData);
      // 월 병합
      mergeDate.forEach(({ title, idx }, index) => {
        const endIndex = mergeDate[index + 1]?.idx - 1;
        if (!endIndex) return;

        const { address: startAddress } = secondRow.getCell(idx);
        const { address: endAddress } = secondRow.getCell(endIndex);
        sheet.mergeCells(`${startAddress}:${endAddress}`);
        secondRow.getCell(idx).value = title;
      });

      // 아이템 데이터 처리
      const items = [];
      const mergeItems = [];
      const groupTitleRows = [];
      let row = 4;
      this.groupList.forEach(({ id: groupId, title: groupTitle, color }) => {
        items.push([groupTitle]);
        groupTitleRows.push(row);
        this.itemObj[groupId].forEach(({ itemTitle, start, end }) => {
          // cell 은 앞에 (타이틀, 시작날짜, 끝날짜, 구분선) 도 포함이기때문에 날짜의 length에 5를 더해준다.
          const cell = new Array(dateArr.length + 5);
          const index = dateArr.findIndex(d => d.fullDate == start) + 4;
          const endIndex = dateArr.findIndex(d => d.fullDate == end) + 4;
          cell[0] = itemTitle;
          cell[1] = start;
          cell[2] = end;
          if (index !== 3) cell[index] = itemTitle;
          items.push(cell);

          row += 1;
          if (index === 3) return;
          mergeItems.push({
            row,
            start: index + 1,
            end: endIndex + 1,
            color: color.replace("#", "")
          });
        });

        row += 1;
      });

      sheet.addRows(items);
      // 그룹 타이틀 스타일 처리
      groupTitleRows.forEach(row => {
        sheet.getRow(row).getCell(1).font = { size: 14, bold: true };
      });
      // 데이터 등록된 날짜 병합
      mergeItems.forEach(({ start, end, row, color }) => {
        const sheetRow = sheet.getRow(row);
        const startCell = sheetRow.getCell(start);
        const { address: endAddress } = sheetRow.getCell(end);
        sheet.mergeCells(`${startCell.address}:${endAddress}`);
        startCell.fill = this.fillObj(color);
      });

      // 날짜 부분 width 조절
      sheet.columns.forEach((column, idx) => {
        if (idx < 4) return;
        column.width = 3;
      });

      // 다운로드
      workbook.xlsx.writeBuffer().then(data => {
        const blob = new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `timeline.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);

        setTimeout(() => {
          this.$emit("update:excelDownload", false);
        }, 0);
      });
    } catch (error) {
      console.error(error);
    }
  },
  computed: {
    ...mapGetters("todoBoard", ["currentBoard"])
  },
  methods: {
    getWorkbook() {
      try {
        // 엑셀 생성
        const workbook = new Excel.Workbook();

        // 생성자
        workbook.creator = "Sirteam";
        // 최종 수정자
        workbook.lastModifiedBy = "Sirteam";
        // 생성일(현재 일자로 처리)
        workbook.created = new Date();
        // 수정일(현재 일자로 처리)
        workbook.modified = new Date();
        // addWorksheet() 함수를 사용하여 엑셀 시트를 추가한다.
        workbook.addWorksheet("Timeline");

        return workbook;
      } catch (e) {
        throw new Error(e);
      }
    },
    setColumns(sheet) {
      const { title: boardTitle } = this.currentBoard;
      sheet.columns = [
        { header: "", key: "title", width: 40 },
        { header: "", key: "start", width: 15 },
        { header: "", key: "end", width: 15 },
        {
          header: "",
          key: "border",
          width: 1,
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "333333" },
              bgColor: { argb: "333333" }
            }
          }
        },
        { header: `Board - ${boardTitle}`, width: 10 }
      ];
    },
    setFirstRow(sheet) {
      const firstRow = sheet.getRow(1);
      firstRow.height = 40;
      firstRow.font = { size: 24, bold: true };
    },
    setSecondRow(sheet) {
      // 두번째 row, 월 - 년도 표시
      sheet.addRow(["", "", "", ""]);
      const secondRow = sheet.getRow(2);
      secondRow.alignment = { horizontal: "center" };
      secondRow.fill = this.fillObj("D3D3D3");
      secondRow._cells[3].style = { fill: this.fillObj("333333") };
      // 타이틀, 시작날짜, 끝날짜 병합
      sheet.mergeCells("A2:C2");

      return secondRow;
    },
    setThirdRow(sheet, thirdRowData) {
      sheet.addRow(thirdRowData);
      const thirdRow = sheet.getRow(3);
      thirdRow.alignment = { horizontal: "center" };
      thirdRow.fill = this.fillObj("EDEDED");
      thirdRow._cells[3].style = { fill: this.fillObj("333333") };
    },
    fillObj(color) {
      return {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: color },
        bgColor: { argb: color }
      };
    },
    // 시작 날짜부터 끝 날짜까지 날짜 생성 함수
    getDateArr() {
      const { firstDate, lastDate } = this.$parent.getDateRange;
      const start = new Date(firstDate);
      const end = new Date(lastDate);
      const dateArr = [];
      let year = start.getFullYear();

      for (year; year <= end.getFullYear(); year += 1) {
        let month = 1;
        let monthLoopMaximum = 12;
        if (year === start.getFullYear()) month = start.getMonth() + 1;
        if (year === end.getFullYear()) monthLoopMaximum = end.getMonth() + 1;

        for (month; month <= monthLoopMaximum; month += 1) {
          let date = 1;
          let loopMaximum = new Date(year, month, 0).getDate();
          if (month === start.getMonth() + 1) date = start.getDate();
          if (month === end.getMonth() + 1) loopMaximum = end.getDate();

          for (date; date <= loopMaximum; date += 1) {
            const fullDate = `${year}-${month < 10 ? "0" : ""}${month}-${
              date < 10 ? "0" : ""
            }${date}`;
            dateArr.push({ fullDate, year, month, date });
          }
        }
      }

      return dateArr;
    }
  }
};
</script>
